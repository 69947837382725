<template>
  <section class="filter-wrap">
    <BasicFilterWrap
      :moreFilter="showMoreFilter"
      @confrim="onSearch"
      @reset="onResetFilter"
      @more="showMoreFilter = !showMoreFilter"
    >
      <el-form ref="form" :model="tableFilters" label-width="auto">
        <el-row :gutter="20">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="中文名称">
              <el-input
                v-model="tableFilters.nameCn"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="英文名称">
              <el-input
                v-model="tableFilters.nameEn"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="城市">
              <el-input
                v-model="tableFilters.cityCn"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-collapse-transition>
          <el-row :gutter="10" v-if="showMoreFilter">
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="国家">
                <el-input
                  v-model="tableFilters.countryCn"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="onSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="省/州">
                <el-input
                  v-model="tableFilters.provinceCn"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="onSearch"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="类型">
                <el-select
                  multiple
                  v-model="tableFilters.typesId"
                  placeholder="请选择类型"
                  class="form-select"
                  clearable
                >
                  <el-option
                    v-for="item in typesOptions"
                    :key="item.id"
                    :label="item.name.i18n"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="开发商">
                <el-select
                  multiple
                  v-model="tableFilters.developerId"
                  placeholder="请选择开发商"
                  class="form-select"
                  clearable
                >
                  <el-option
                    v-for="item in developersOptions"
                    :key="item.id"
                    :label="item.name.i18n"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="设计方">
                <el-select
                  multiple
                  v-model="tableFilters.designerId"
                  placeholder="请选择设计方"
                  class="form-select"
                  clearable
                >
                  <el-option
                    v-for="item in designersOptions"
                    :key="item.id"
                    :label="item.name.i18n"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建日期">
                <el-date-picker
                  v-model="tableFilters.createdAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新日期">
                <el-date-picker
                  v-model="tableFilters.lastModifiedAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新人">
                <BasicAsyncSelect
                  v-model="tableFilters.lastModifiedById"
                  :asyncObj="{
                    dataFun: GetUsersList,
                  }"
                />
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建人">
                <BasicAsyncSelect
                  v-model="tableFilters.createdById"
                  :asyncObj="{
                    dataFun: GetUsersList,
                  }"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-transition>
      </el-form>
    </BasicFilterWrap>
  </section>
</template>

<script>
import BasicAsyncSelect from "@/components/BasicAsyncSelect";
import { onClearFilter } from "@/utils/table";
import BasicFilterWrap from "@/components/BasicFilterWrap";
import { GetUsersList } from "@/views/users/api";
import options from "../mixins/options";
export default {
  components: {
    BasicAsyncSelect,
    BasicFilterWrap,
  },
  mixins: [options],
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        createdAt: null,
        createdById: null,
        lastModifiedAt: null,
        lastModifiedById: null,
        nameCn: "",
        nameEn: "",
        typesId: [],
        productsId: [],
        developerId: [],
        designerId: [],
        areaId: [],
        countryCn: "",
        provinceCn: "",
        cityCn: "",
      },
    };
  },
  computed: {
    _userOptions() {
      return this.$store.getters.userOptions;
    },
  },
  created() {
    this.initFormOptions();
  },
  methods: {
    GetUsersList,
    onSearch() {
      this.$emit("change", this.tableFilters);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.initFormOptions();
      this.$emit("change", this.tableFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select {
  width: 100%;
}
.filter-wrap {
  box-sizing: border-box;
  margin: 10px 10px 0 10px;
  padding: 10px;
  background-color: #fff;
}
</style>
