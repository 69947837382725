<template>
  <div class="component-wrap" v-loading="loading">
    <el-transfer
      :titles="['所有产品', '选中产品']"
      filterable
      :filter-method="filterMethod"
      filter-placeholder="名称/ID关键词过滤"
      v-model="selectIds"
      :data="sourceDatas"
      @change="onTransferChange"
    >
      <span slot-scope="{ option }" :title="option.label">
        <el-popover
          placement="right"
          :title="option.label"
          width="200"
          trigger="hover"
        >
          <div style="font-size:12px">
            <p>ID：{{ option.key }}</p>
            <p class="text-danger" v-if="option.data.disabled">
              当前产品已被禁用
            </p>
            <p>
              <el-button
                :type="option.data.disabled ? 'success' : 'danger'"
                size="mini"
                @click="toggleDisableRow(option.data, option)"
                >{{ option.data.disabled ? "启用" : "禁用" }}</el-button
              >
            </p>
          </div>
          <span slot="reference"> <i class="el-icon-view"></i></span>
        </el-popover>
        <span>&nbsp;&nbsp;{{ option.label }}</span>
      </span>
      <el-button
        class="transfer-footer mt-l"
        slot="left-footer"
        size="small"
        @click="onRefresh"
        >刷新</el-button
      >
    </el-transfer>
  </div>
</template>

<script>
import { GetProductsList, ToogleDisableByIds } from "../api";
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabPosition: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      loading: false,
      sourceDatas: [],
      checkOptions: [],
      selectIds: [],
      filterName: "",
    };
  },
  created() {
    this.getSourceData();
  },
  watch: {
    value: {
      handler() {
        this.upDateValue();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    filterMethod(query, item) {
      if (item.label.indexOf(query) > -1) {
        return true;
      }
      return false;
    },
    upDateValue() {
      if (this.sourceDatas.length > 0 && this.value.length > 0) {
        this.selectIds = [...this.value];
      } else {
        this.selectIds = [];
      }
    },
    isCheck(role) {
      return this.selectIds.some((dataId) => dataId === role.id);
    },
    getSourceData() {
      this.loading = true;
      GetProductsList({
        page: 0,
        size: 999,
        sort: ["disabled,asc", "seq,desc"],
        nameCn: this.filterName,
      })
        .then((res) => {
          let { content } = res.data;
          let _datas = this.buildTransferData(content);
          this.sourceDatas = [...this.sourceDatas, ..._datas];
          this.formatRoleOptions();
          this.upDateValue();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async formatRoleOptions() {
      let res = await GetProductsList({
        page: 0,
        size: 50,
        nameCn: this.filterName,
      });
      let { content } = res.data;
      this.checkOptions = this.buildTransferData(content);
    },
    onRefresh() {
      this.selectIds = [];
      this.sourceDatas = [];
      this.checkOptions = [];
      this.getSourceData();
    },
    buildTransferData(datas = []) {
      if (datas && datas.length > 0) {
        return datas.map((item) => {
          return {
            key: item.id,
            label: `${item.name.cn}-${item.code}`,
            data: item,
            disabled: item.disabled,
          };
        });
      }
      return [];
    },
    onTransferChange() {
      this.$emit("input", this.selectIds);
      let items = this.sourceDatas.map((item) =>
        this.selectIds.includes(item.id)
      );
      this.$emit("change", this.selectIds, items);
    },
    toggleDisableRow(row, option) {
      ToogleDisableByIds([row.id], !row.disabled).then(() => {
        option.disabled = !row.disabled;
        row.disabled = !row.disabled;
        this.$message.success("操作成功!");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.component-wrap {
  box-sizing: border-box;
  min-height: 352px;
  width: 100%;
  margin: 0 auto;
  ::v-deep {
    .el-tabs__content {
      box-sizing: border-box;
      max-height: 500px;
      overflow-y: auto;
    }
    .el-transfer {
      position: absolute;
      box-sizing: border-box;
      padding-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
    }
    .el-transfer-panel {
      width: auto;
      min-width: 180px;
      max-width: 48%;
    }
    .el-transfer__buttons {
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
    .el-transfer__button {
      padding: 8px 12px;
    }
  }
  .role-tag {
    margin-right: 20px;
    margin-bottom: 15px;
    cursor: pointer;
  }
}
.roles-search-wrap {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  .search-type {
    flex: 180px 0 0;
  }
  .search-input {
    flex: 1;
    .input-with-select {
      width: 100%;
    }
  }
  .search-btn {
    flex: auto 0 0;
  }
}
</style>
