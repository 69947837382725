import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["areasOptions", "designersOptions", "developersOptions", "typesOptions"]),
  },
  methods: {
    ...mapActions({
      refreshAreasOptions: "cases/refreshAreasOptions",
      refreshDesignersOptions: "cases/refreshDesignersOptions",
      refreshColorsOptions: "cases/refreshDevelopersOptions",
      refreshSeriesOptions: "cases/refreshTypesOptions",
    }),
    initFormOptions() {
      this.refreshAreasOptions();
      this.refreshDesignersOptions();
      this.refreshColorsOptions();
      this.refreshSeriesOptions();
    },
  },
}